/**
 * Les types d'environnement du portail entreprises
 */
const StatutTache = {
  EN_ATTENTE: "EN_ATTENTE",
  TERMINE: "TERMINE",
  ECHOUE: "ECHOUE",
};

export { StatutTache };
