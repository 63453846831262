import { neufsneufSettings } from "../services/settings-loader";
import {
  LNC_ESPACE,
  LncMonCompteClientService,
} from "@odw/lnc-services/js-services/api-client/lnc-mon-compte-client.service";
import MesEntreprisesService from "./mes-entreprises.service";
import MesTachesApiClientService from "./mes-taches.api.client.service";

const mesEntreprisesService = new MesEntreprisesService(neufsneufSettings.url.mes_entreprises_api_url);
const monCompteClientService = new LncMonCompteClientService(
  neufsneufSettings.url.mon_compte_client_api_url,
  LNC_ESPACE.ENTREPRISE
);
const mesTachesService = new MesTachesApiClientService(neufsneufSettings.url.mes_taches_api_url);

export { mesEntreprisesService, monCompteClientService, mesTachesService };
