/**
 * Cookie portail
 */
import { BaseLocalStorage } from "./base-local-storage";

class NeufsneufPortailLocalStorage extends BaseLocalStorage {
  /**
   * Initialise une instance de type {@type NeufsneufPortailLocalStorage}.
   */
  constructor() {
    super("9s9_portail_entreprises");
  }

  /**
   * Get les taches de l'utilisateur depuis le local storage
   * @return {TacheDto[]} les taches de l'utilisateur
   */
  get taches() {
    return this.get("taches");
  }

  /**
   * Set les taches de l'utilisateur depuis le local storage
   * @param {TacheDto[]} value les taches de l'utilisateur
   */
  set taches(value) {
    this.set("taches", value);
  }
}

const PortailLocalStorage = new NeufsneufPortailLocalStorage();
export { PortailLocalStorage };
