import MicroApp from "./micro-app-base";
import { monCompteClientService } from "../../api-client/index";
import { StoreManager } from "../../services/store-manager";
import { modalConfirmationModification, modalConfirmationSuppression } from "../../../pages/gestion-profil/main";

/**
 * Microapp compte client
 */
export default class CompteClientMicroApp extends MicroApp {
  /**
   * Surcharge pour réagir aux messages de la microapp
   */
  childPymEvents = {
    /**
     * Event pour mettre à jour les informations de la personne connectée
     */
    changementIdentifiantUser: function () {
      monCompteClientService.getUserInfosV2(StoreManager.cnAlex).then((response) => {
        StoreManager.infoCompte = response;
      });
    },
    /**
     * Event pour afficher la modale de confirmation relative à la suppression d'un canal de contact
     *
     * @param {string} value - Un string contenant le type de canal de contact et le canal de contact séparé par un `/`
     */
    suppression_canal_contact: function (value) {
      modalConfirmationSuppression.show(value.split("/"));
    },
    /**
     * Event pour afficher la modale de confirmation relative à la modification d'un canal de contact
     *
     * @param {string} value - Un string contenant le type de canal de contact et le canal de contact séparé par un `/`
     */
    modification_canal_contact: function (value) {
      modalConfirmationModification.show(value.split("/"));
    },
    affichage_toast: function () {
      let toast = document.querySelector("neufsneuf-toast");
      toast.setToast("Veuillez valider ou annuler votre modification", "true", "8000", "M", "", "blanc", "neutre-700");
      toast.show();
    },
    modification_profil_erreur: function () {
      let toast = document.querySelector("neufsneuf-toast");
      toast.setToast(
        "Le service est momentanément indisponible. Veuillez réessayer ultérieurement.",
        "true",
        "8000",
        "M",
        "",
        "",
        "",
        "Error"
      );
      toast.show();
    },
    modification_profil_success: function () {
      let toast = document.querySelector("neufsneuf-toast");
      toast.setToast("Votre modification a été prise en compte", "true", "8000", "M", "", "", "", "Success");
      toast.show();
    },
  };

  /**
   * Initialise une nouvelle instance de type {@type CompteClientMicroApp}.
   */
  constructor() {
    super();
  }
}
