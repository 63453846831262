import { NeufsneufPageManager } from "../../services/page-manager";
import { PageTypes } from "../../services/settings-loader";
import { TagCommanderManager } from "../../services/tag-commander-manager";
import { CLICK_CONDITIONS_GENERALES_LINK, FERMETURE_MODAL_CONDITIONS_GENERALES } from "../../tag-plan";
import { NeufsneufModal } from "../common/neufsneuf-modal";

/**
 * La modal d'information concernant les conditions générales des contrats et avenants.
 */
export default class ModalConditionsGenerales extends NeufsneufModal {
  /**
   * Initialise une nouvelle instance de type {@type ModalConditionsGenerales}.
   */
  constructor() {
    super("modal-conditions-generales");

    this.onDismiss(() => {
      TagCommanderManager.tagClick(FERMETURE_MODAL_CONDITIONS_GENERALES);
    });

    // Ecoute le clic sur le lien
    this.element?.querySelector("#cg-link").addEventListener("click", this._openCgLink.bind(this));
  }

  /**
   * Ouvre le lien vers les documents dans un nouvel onglet
   * @param {*} event
   */
  _openCgLink(event) {
    event.preventDefault();
    TagCommanderManager.tagClick(CLICK_CONDITIONS_GENERALES_LINK);
    NeufsneufPageManager.openLinkInNewTab(
      PageTypes.CONTRATS_AVENANTS.modalConditionsGenerales.urlDocumentationDeReference
    );
  }
}
