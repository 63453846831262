/**
 * Service pour ouvrir les modals
 */
class ComponentHelper {
  /**
   * Initialise une instance de type {@type ComponentHelper}.
   * @private
   */
  constructor() {}

  /**
   * Action pour ouvrir la modal.
   * @param modal {string} la modal à ouvrir de type {@type ModalTypes}.
   * @param value {string=} la valeur passée à la modal via l'attribut html data-value
   */
  static openModal(modal, value) {
    const modalComponent = document.querySelector(modal);
    if (value) {
      modalComponent.setAttribute("data-value", value);
    }
    modalComponent.setAttribute("display", "true");
  }

  /**
   * Action pour charger les microapps présentes dans le DOM.
   * @returns {NodeListOf<MicroApp>} Les microapps présentes dans le DOM.
   */
  static getMicroapps() {
    let selector = "div[is^=micro-app]";
    return document.querySelectorAll(selector);
  }

  /**
   * Action pour positionner un attribut sur les microapps présentes dans le DOM.
   * @param attr {string} Le nom de l'attribut
   * @param value {string} La valeur de l'attribut
   */
  static setMicroappsAttr(attr, value) {
    ComponentHelper.getMicroapps().forEach((microapp) => {
      microapp.setAttribute(attr, value);
    });
  }

  /**
   * Action pour déclencher l'affichage des microapps présentes dans le DOM.
   */
  static displayMicroapps() {
    ComponentHelper.setMicroappsAttr("display", "true");
  }

  /**
   * Action pour envoyer un message pym aux microapps présentes dans le DOM.
   * @param key {string} La clé du message pym
   * @param value {string} La valeur du message
   */
  static pymSendMessage(key, value) {
    ComponentHelper.getMicroapps().forEach((microApp) => {
      if (typeof microApp.pymSendMessage === "function") {
        microApp.pymSendMessage(key, value);
      }
    });
  }
}

export { ComponentHelper };
