import { PageManager } from "../../services/page-manager";
import { PageTypes } from "../../services/settings-loader";

/**
 * Composant pour le bloc microapp
 */
export default class NeufsneufMicroapp extends HTMLElement {
  /**
   * Initialise une nouvelle instance de type {@type NeufsneufMicroapp}
   */
  constructor() {
    super();
    this._buildMicroapp();
    this.querySelector(".maintenance-microapp #retour-accueil")?.addEventListener("click", this._onRetourAccueil);
  }

  /**
   * Construction du bloc pour afficher une micro app
   * @private
   */
  _buildMicroapp() {
    const blocMicroapp = this.querySelector("#neufsneuf-master-microapp");
    blocMicroapp.parentElement.classList.add(this.microappId);
    blocMicroapp.insertAdjacentHTML(
      "beforebegin",
      `<div id="${this.microappId}" is="${this.microappConfig.is}" class="embed-responsive"></div>`
    );
  }

  /**
   * Retourne la config de la microapp
   * @returns {MicroappConfig}
   */
  get microappConfig() {
    return PageManager.current.microapps[this.microappId];
  }

  /**
   * Retourne l'ID de la microapp
   * Si l'id de la microapp n'est pas renseigné sur le custom-element alors ce sera l'id de la première microapp
   * configurée qui sera retourné
   * @returns {String}
   */
  get microappId() {
    return this.dataset.microappId || Object.keys(PageManager.current.microapps)[0];
  }

  /**
   * Callback appelée au clic du bouton pour rediriger vers la page d'accueil
   */
  _onRetourAccueil() {
    PageManager.redirectTo(PageTypes.RACINE);
  }
}
