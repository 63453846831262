import { BaseCookie } from "./base-cookie";
import { neufsneufSettings } from "./settings-loader";

/**
 * Cookie pour échanger avec les microapps
 */
class NeufsneufCookieMicroapp extends BaseCookie {
  /**
   * Initialise une nouvelle instance de type {@type NeufsneufCookieMicroapp}.
   * @param {string} name Le nom du cookie.
   * @param {boolean} encode Une valeur indiquant si la valeur du cookie est encodée
   */
  constructor(name, encode) {
    super(name, neufsneufSettings.envs.cookie_domain, encode);
  }

  /**
   * Get le siren sélectionné
   * @return {string} Le siren sélectionné
   */
  get selectedSiren() {
    return this.get("selected_siren");
  }

  /**
   * Get le siren model sélectionné
   * @return {string} Le siren model sélectionné {@link SirenModel} au format JSON string
   */
  get selectedSirenModel() {
    return this.get("selected_siren_model");
  }

  /**
   * Récupères les prms sélectionnés
   * @return {string[]} Les prms sélectionnés
   */
  get selectedPrms() {
    return this.get("selected_prms");
  }

  /**
   * Set le siren sélectionné
   * @param siren {string} Le siren sélectionné
   */
  set selectedSiren(siren) {
    return this.set("selected_siren", siren);
  }

  /**
   * Set le siren model sélectionné
   * @param sirenModel {string} Le siren model sélectionné {@link SirenModel} au format JSON string
   */
  set selectedSirenModel(sirenModel) {
    return this.set("selected_siren_model", sirenModel);
  }

  /**
   * Set les prms sélectionnés
   * @param {string[]} prms Les prms sélectionnés
   */
  set selectedPrms(prms) {
    return this.set("selected_prms", prms);
  }
}

const CookieMicroapp = new NeufsneufCookieMicroapp("9s9_portail_entreprises_microapp", false);

export { CookieMicroapp };
