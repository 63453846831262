import { LncBaseApiClientService } from "@odw/lnc-services/js-services/api-client/lnc-base-api-client.service";
import { StatutRattachement } from "./api-client-enums";

/**
 * Service pour réaliser les appels à l'api entreprise client.
 */
export default class MesEntreprisesService extends LncBaseApiClientService {
  /**
   *  Action pour récupérer les demandes en un seul appel.
   * @param cnAlex {string} L'identifiant alex de la personne.
   * @returns {Promise<{number,number,number}>} La promesse d'une réponse.
   */
  getEtatDemandes(cnAlex) {
    return new Promise((resolve, reject) => {
      this.get(
        "/api/private/v1/personnes/" +
          cnAlex +
          "/demandes?statut=" +
          [StatutRattachement.ENCOURS.name, StatutRattachement.REFUSE.name]
      )
        .then((response) => {
          const etatDemandes = { enCours: 0, refusees: 0 };
          if (response.status === 204) {
            resolve(etatDemandes);
          } else {
            response.content.forEach((values) => {
              etatDemandes.enCours += values.statut === StatutRattachement.ENCOURS.name ? 1 : 0;
              etatDemandes.refusees += values.statut === StatutRattachement.REFUSE.name ? 1 : 0;
            });
            resolve(etatDemandes);
          }
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    });
  }

  /**
   * Action pour récupérer les habilitations de la personne. Ceci correspond aux demandes validées de l'utilisateur.
   * @param cnAlex {string} L'identifiant alex de la personne.
   * @returns {Promise<HabilitationDetailleeDto[]>} La promesse d'une réponse.
   */
  getHabilitation(cnAlex) {
    return new Promise((resolve, reject) => {
      this.get("/api/private/v1/personnes/" + cnAlex + "/habilitations")
        .then((response) => {
          if (response.status === 204) {
            resolve([]);
          } else {
            /**
             * Les habilitations de la personne connectée
             * @type {HabilitationDetailleeDto[]}
             */
            const habilitations = response.content;
            resolve(habilitations.sort((a, b) => a.raisonSociale.localeCompare(b.raisonSociale)));
          }
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    });
  }

  /**
   * Action pour générer le token de vérification pour Mon Entreprise Legacy.
   * @param cnAlex {string} L'identifiant alex de la personne.
   * @param siren {string} Le siren sélectionné.
   * @returns {Promise<EncryptedDataDto>} La promesse d'une réponse.
   */
  getInfosLegacy(cnAlex, siren) {
    return new Promise((resolve, reject) => {
      this.get("/api/private/v1/personnes/" + cnAlex + "/entreprises/" + siren + "/infos-legacy")
        .then((response) => {
          if (response.status === 204) {
            resolve({});
          } else {
            resolve(response.content);
          }
        })
        .catch(() => {
          reject();
        });
    });
  }
}
