import { StoreManager } from "../services/store-manager";
import { StoreKeys } from "../services/enums";
import { mesEntreprisesService } from "../api-client/index";

// TODO @TITAN code à déplacer dans main.js de la page ou est utilisé l'encart admin
export default class EncartAdmin extends HTMLDivElement {
  constructor() {
    super();
    StoreManager.subscribe(StoreKeys.INFO_COMPTE, this.getAndDisplayHabilitation.bind(this));
  }

  /**
   * Requête de l'api getHabilitation puis affiche les habilitations
   */
  async getAndDisplayHabilitation() {
    let listFonctions = [];

    // TODO @TITAN à déplacer dans loadUserInstance / remplacer getPerimetre ?
    // TODO @TITAN le jdd mocké ne fonctionne pas
    // TODO @TITAN utiliser directement le StoreManager pour la récupération des habilitations
    await mesEntreprisesService
      .getHabilitation(StoreManager.cnAlex)
      .then((habilitationData) => {
        habilitationData.forEach((habilitation) => {
          habilitation.fonctions.forEach((element) => {
            if (listFonctions.indexOf(element) === -1) {
              listFonctions.push(element);
            }
          });
        });
      })
      .catch((e) => {
        console.log(e);
      });

    const fonctionGestionUtilisateurs = document.getElementById("gerer_utilisateur");
    const fonctionCanauxNumeriques = document.getElementById("canaux_numériques");

    if (listFonctions.length > 1 && listFonctions.includes(CodeFonction.ACCES_ENTREPRISE)) {
      const encart = document.getElementById("encart-admin");
      encart.classList.remove("hidden");
      if (listFonctions.includes(CodeFonction.GESTION_UTILISATEURS)) {
        fonctionGestionUtilisateurs.classList.remove("hidden");
      }
      if (listFonctions.includes(CodeFonction.GESTION_SDD)) {
        fonctionCanauxNumeriques.classList.remove("hidden");
      }
    }
  }
}
