import ModalBase from "./modal-base";
import { StoreManager } from "../../services/store-manager";

/**
 * La modal pour ouvrir un lien
 */

export default class ModalExternalLink extends ModalBase {
  /**
   * Initialise une nouvelle instance de type {@type ModalExternalLink}.
   */
  constructor() {
    super();
  }

  /**
   * Action pour réagir au clic OK.
   * @override
   */
  _onAccept() {
    window.open(this.dataset.href);
    this._closeModal();
  }

  /**
   * Action pour réagir au clic "Ne plus afficher".
   * @override
   */
  _onDismiss() {
    StoreManager.externalLinkAskLater = true;
    window.open(this.dataset.href);
    this._closeModal();
  }
}
