import MicroApp from "./micro-app-base";
import { ComponentHelper } from "../../services/component-helper";
import { TagCommanderManager } from "../../services/tag-commander-manager";
import { POPIN_GENERATION_NOUVELLE_CLE, SUPPRESSION_DU_CANAL_NUMERIQUE } from "../../tag-plan";
import ModalAnnulationSaisie from "../../../pages/vos-canaux-numeriques/modal-annulation-saisie";

/**
 * Microapp gestion de compte
 */
export default class EntreprisesMicroApp extends MicroApp {
  /**
   * Surcharge pour réagir aux messages de la microapp
   */
  childPymEvents = {
    openModalConfirmationAnnulation: function (params) {
      ModalAnnulationSaisie.pymjsData = JSON.parse(params);
      ComponentHelper.openModal("#modal-annulation-saisie");
    },
    openModalConfirmationSuppression: function (params) {
      TagCommanderManager.tagClick(SUPPRESSION_DU_CANAL_NUMERIQUE);
      ComponentHelper.openModal("#modal-confirmation-suppression");
    },
    openModalGenerationCleChiffrement: function (params) {
      TagCommanderManager.tagClick(POPIN_GENERATION_NOUVELLE_CLE);
      ComponentHelper.openModal("#modal-generation-cle-chiffrement");
    },
    openToastCopierCle: function (params) {
      let myToast = document.querySelector("neufsneuf-toast");
      myToast.setToast("La clé de chiffrement a été copiée", "true", "0", "M", "error_outline", "blanc", "neutre-700");
      myToast.show();
    },
    openToastCopierTechnique: function (params) {
      let myToast = document.querySelector("neufsneuf-toast");
      myToast.setToast(
        "La référence technique du canal a été copiée",
        "true",
        "0",
        "M",
        "error_outline",
        "blanc",
        "neutre-700"
      );
      myToast.show();
    },
  };

  /**
   * Initialise une nouvelle instance de type {@type EntreprisesMicroApp}.
   */
  constructor() {
    super();
  }
}
