/**
 * Les valeurs possibles pour l'enum StatutRattachement
 */
class StatutRattachement {
  static ENCOURS = new StatutRattachement("ENCOURS");
  static REFUSE = new StatutRattachement("REFUSE");
  static VALIDE = new StatutRattachement("VALIDE");

  /**
   * Initialise une instance de type {@type StatutRattachement}.
   * @private
   * @param name {string} le statut.
   */
  constructor(name) {
    this.name = name;
  }
}

/**
 * Les valeurs possibles pour l'enum CodeFonction
 * {@type CodeFonction}
 */
const CodeFonction = {
  ACCES_ENTREPRISE: "ACCES_ENTREPRISE",
  GESTION_AUTORISATION: "GESTION_AUTORISATION",
  GESTION_SDD: "GESTION_SDD",
  GESTION_UTILISATEURS: "GESTION_UTILISATEURS",
};

export { StatutRattachement, CodeFonction };
