import ModalBase from "./modal-base";
import { TagCommanderManager } from "../../services/tag-commander-manager";
import { PageManager } from "../../services/page-manager";

/**
 * La modal pour se déconnecter
 */
export default class ModalLogout extends ModalBase {
  /**
   * Initialise une nouvelle instance de type {@type ModalLogout}.
   */
  constructor() {
    super();
  }

  /**
   * Action pour réagir à l'ouverture de la modal
   * @override
   */
  _onShowModal() {
    TagCommanderManager.tagClick({
      page: "deconnexion",
    });
  }

  /**
   * Action pour réagir au clic déconnexion OK.
   * @override
   */
  _onAccept() {
    TagCommanderManager.tagClick({
      chapitre_1: "deconnexion",
      page: "valider",
      type: "action",
    });
    PageManager.logout();
  }

  /**
   * Action pour réagir au clic déconnexion NOK.
   * @override
   */
  _onDismiss() {
    TagCommanderManager.tagClick({
      chapitre_1: "deconnexion",
      page: "annuler",
      type: "action",
    });
    this._closeModal();
  }
}
