/**
 * Transforme les settings json en objet
 * @param {string} idSettings L'identifiant pour retrouver le json dans le DOM
 * @return {{}|any} Les settings
 */
function loadJsonSettings(idSettings) {
  const settingsElement = document.querySelector(
    "body > script[type='application/json'][data-9s9-settings='" + idSettings + "']"
  );

  if (settingsElement !== null) {
    return JSON.parse(settingsElement.textContent);
  }

  return {};
}

/**
 * Les settings pour le portail entreprises.
 * @type {Appconfig}
 */
let neufsneufSettings = loadJsonSettings("9s9-settings-json");
// transformer date cgu en Date
let dateParts = neufsneufSettings.last_cgu_update.split("/");
neufsneufSettings.last_cgu_update = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
console.log("SETTINGS_CGU: " + neufsneufSettings.last_cgu_update);

/**
 * Les settings pour le portail.
 * @type {MasterpageConfig}
 */
const neufsneufMasterpageSettings = loadJsonSettings("9s9-masterpagesettings-json");
/**
 * La config des menus principaux dans le header du portail.
 * @type {HeaderMenuConfig}
 */
const neufsneufHeaderMenuSettings = loadJsonSettings("9s9-header-menu-settings-json");
/**
 * La config des sous menu dans le header du portail.
 * Il faudrait typer cet objet via la jsdoc.
 * @type {HeaderNavBar[]}
 */
const neufsneufHeaderNavbarSettings = loadJsonSettings("9s9-header-navbar-settings-json");
/**
 * La config du menu dans le footer du portail.
 * /!\ Cette configuration n'est pas variabilisé pour le moment, voir devops/pack/nginx/src/main/resources/appconfig/replace_appconfig.sh
 * @type {FooterConfig}
 */
const neufsneufFooterSettings = loadJsonSettings("9s9-footer-settings-json");

export {
  neufsneufHeaderMenuSettings as HeaderMenuData,
  neufsneufHeaderNavbarSettings as NavbarData,
  neufsneufMasterpageSettings as PageTypes,
  neufsneufFooterSettings as footerData,
  neufsneufSettings,
};
