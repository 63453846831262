import { StoreManager } from "./store-manager";
import { mesTachesService } from "../api-client";
import { neufsneufSettings } from "./settings-loader";
import { StatutTache } from "../api-client/enums";
import { StoreKeys } from "./enums";

/**
 * Tache Manager
 * @constructor
 * @public
 */
class NeufsneufTacheManager {
  /**
   * ID d'intervalle qui identifie de manière unique l'intervalle
   */
  intervalId;

  /**
   * Initialise une instance de type {@type NeufsneufTacheManager}
   */
  constructor() {
    StoreManager.subscribe(StoreKeys.INFO_COMPTE, this._startInterval.bind(this));
  }

  /**
   * Relancer le téléchargement de la tache
   * @public
   * @param idTache {string}
   * @return {Promise<void|*>}
   */
  relancer(idTache) {
    return new Promise((resolve, reject) => {
      const tacheIndex = StoreManager.taches.liste.findIndex((tache) => tache.id === idTache);
      if (tacheIndex === -1) {
        reject(null);
      }

      const tache = StoreManager.taches.liste[tacheIndex];
      const tacheClone = { ...tache };

      tache.statut = StatutTache.EN_ATTENTE;
      tache.version = tache.version + 1;
      this.ajouter(tache);

      mesTachesService
        .modifierTache(StoreManager.cnAlex, tache)
        .then((tache) => {
          resolve();
        })
        .catch((err) => {
          this._rollBack(tacheClone);
          reject(err);
        });
    });
  }

  /**
   * Ajoute les taches dans le store et le local storage
   * @public
   * @param {TacheDto} tache Le tacheDto
   */
  ajouter(tache) {
    StoreManager.tachesTemp = this._mergerTaches(tache, StoreManager.tachesTemp);
    const taches = this._mergerTaches(tache, StoreManager.taches.liste);
    StoreManager.taches = {
      statutOk: StoreManager.taches.statutOk,
      liste: taches,
    };
  }

  /**
   * Remplace la tache dans taches et tachesTemp du store
   * @param tacheRollback {TacheDto} La tache
   * @private
   */
  _rollBack(tacheRollback) {
    const tacheTempIndex = StoreManager.tachesTemp.findIndex((tache) => tache.id === tacheRollback.id);
    if (tacheTempIndex !== -1) {
      StoreManager.tachesTemp[tacheTempIndex] = tacheRollback;
      StoreManager.tachesTemp = StoreManager.tachesTemp;
    }

    const tacheIndex = StoreManager.taches.liste.findIndex((tache) => tache.id === tacheRollback.id);
    if (tacheIndex !== -1) {
      StoreManager.taches.liste[tacheIndex] = tacheRollback;
      StoreManager.taches = {
        statutOk: StoreManager.taches.statutOk,
        liste: StoreManager.taches.liste,
      };
    }
  }

  /**
   * Merge la nouvelle tache avec la liste de taches.
   * On ajoute la nouvelle tache si elle n'est pas déjà dans la liste.
   * Sinon on remplace la tache existante par la nouvelle si sa version est plus récente.
   * @private
   * @param {TacheDto} tache La tache à merger
   * @param {TacheDto[]} taches La liste de taches
   *
   * @return {TacheDto[]} taches La liste de taches mis à jour
   */
  _mergerTaches(tache, taches) {
    let indexTacheExist = taches.findIndex((el) => tache.id === el.id);

    if (indexTacheExist === -1) {
      taches.push(tache);
    } else {
      if (tache.version > taches[indexTacheExist].version) {
        taches[indexTacheExist] = tache;
      }
    }
    return taches;
  }

  /**
   * Synchronise la liste des taches récupérées via api mes-taches
   * avec la liste des taches temporaires
   *
   * @private
   * @param {TacheDto[]} tachesTemp La liste de taches temporaires
   * @param {TacheDto[]} taches La liste de taches
   *
   * @return {TacheDto[]} taches La liste de taches mis à jour
   */
  _synchroTaches(tachesTemp, taches) {
    if (!tachesTemp) {
      return taches;
    }

    tachesTemp.forEach((tacheTemp) => {
      taches = this._mergerTaches(tacheTemp, taches);
    });
    return taches;
  }

  /**
   * Lancer la récupération des taches
   * @param infoCompte {InfoCompteDto}
   * @private
   */
  _startInterval(infoCompte) {
    this._fetchTaches();
    this.intervalId = setInterval(this._fetchTaches.bind(this), neufsneufSettings.tache_refresh_interval);

    document.onvisibilitychange = () => {
      if (document.visibilityState === "hidden") {
        clearInterval(this.intervalId);
        this.intervalId = undefined;
      } else if (!this.intervalId) {
        this.intervalId = setInterval(this._fetchTaches.bind(this), neufsneufSettings.tache_refresh_interval);
      }
    };
  }

  /**
   * Récupère la liste des taches depuis l'api mes-taches client
   * à intervalles réguliers (delai = tache_refresh_interval)
   * @private
   */
  _fetchTaches() {
    mesTachesService
      .getListeTaches(StoreManager.cnAlex)
      .then((taches) => {
        const tachesSynchronized = this._synchroTaches(StoreManager.tachesTemp, taches);
        StoreManager.taches = {
          statutOk: true,
          liste: tachesSynchronized,
        };
      })
      .catch((e) => {
        StoreManager.taches = { statutOk: false, liste: [] };
      });
  }
}

const TacheManager = new NeufsneufTacheManager();
export { TacheManager };
