import { BaseCookie } from "./base-cookie";
import { NeufsneufProfiles } from "./enums";
import { neufsneufSettings } from "./settings-loader";

/**
 * Cookie portail
 */
class NeufsneufCookiePortail extends BaseCookie {
  /**
   * Initialise une instance de type {@type NeufsneufCookiePortail}.
   */
  constructor() {
    super("9s9_portail_entreprises", location.hostname, neufsneufSettings.envs.profile === NeufsneufProfiles.PROD);
  }

  /**
   * Get l'identifiant interne de l'utilisateur depuis le cookie
   * @return {string|null} L'identifiant interne de l'utilisateur
   */
  get interneId() {
    return this.get("interne_id");
  }

  /**
   * Set l'identifiant interne de l'utilisateur dans le cookie
   * @param {string} value L'identifiant interne de l'utilisateur
   */
  set interneId(value) {
    this.set("interne_id", value);
  }

  /**
   * Get une valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   * @return {string|null} Une valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   */
  get externalLinkAskLater() {
    return this.get("external_link_ask_Later");
  }

  /**
   * Set valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   * @param {string} value La valeur indiquant si l'utilisateur veut afficher la modal des liens externes.
   */
  set externalLinkAskLater(value) {
    this.set("external_link_ask_Later", value);
  }
}

const CookiePortail = new NeufsneufCookiePortail();
export { CookiePortail };
