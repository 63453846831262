/**
 * Service local storage générique
 */
class BaseLocalStorage {
  /**
   * Initialise une nouvelle instance de {@type BaseLocalStorage}.
   */
  constructor(name) {
    this.name = name;
  }

  /**
   * Vider le local storage.
   */
  reset() {
    this._setLocalStorage({});
  }

  /**
   * Invalider le local storage.
   */
  delete() {
    localStorage.removeItem(this.name);
  }

  /**
   * Get la valeur correspondant à la clé
   * @param {string} key  La clé
   * @return {string|null}  La valeur correspondant à la clé ou null si la clé n'existe pas
   */
  get(key) {
    const data = this._readLocalStorage();
    if (data[key]) {
      return data[key];
    }
    return null;
  }

  /**
   * Ajoute au local storage le couple clé-valeur passé en paramètre
   * @param {string} key  La clé
   * @param {string} value  La valeur
   */
  set(key, value) {
    const data = this._readLocalStorage();
    data[key] = value;
    this._setLocalStorage(data);
  }

  /**
   * Retourne l'objet stocké dans le local storage au format json
   * @private
   * @return {*} L'objet stocké dans le local storage au format json
   */
  _readLocalStorage() {
    const content = localStorage.getItem(this.name);
    if (!content) {
      return {};
    }

    try {
      return JSON.parse(content);
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  /**
   * Creation du local storage avec l'objet au format json
   * @private
   * @param {*} data L'objet à mettre dans le local storage au format json
   */
  _setLocalStorage(data) {
    try {
      let json = JSON.stringify(data);
      localStorage.setItem(this.name, json);
    } catch (e) {
      console.error(e);
    }
  }
}

export { BaseLocalStorage };
