/**
 * Service cookie générique
 */
class BaseCookie {
  /**
   * Initialise une nouvelle instance de type {@type BaseCookie}
   * @param {string} name Le nom du cookie
   * @param {string} domain Le nom de domaine
   * @param {boolean} encode Une valeur indiquant si la valeur du cookie est encodée
   */
  constructor(name, domain, encode = true) {
    this.name = name;
    this.domain = domain;
    this.encode = encode;
  }

  /**
   * Vider le cookie
   */
  reset() {
    this._setCookie({});
  }

  /**
   * Invalider le cookie
   */
  delete() {
    document.cookie = this.name + "= ; expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=" + this.domain + ";";
    return true;
  }

  /**
   * Get la valeur correspondant à la clé
   * @param {string} key  La clé
   * @return {string|null}  La valeur correspondant à la clé ou null si la clé n'existe pas
   */
  get(key) {
    const cookie = this._readCookie();
    if (cookie[key]) {
      return cookie[key];
    }
    return null;
  }

  /**
   * Ajoute au cookie le couple clé-valeur passé en paramètre
   * @param {string} key  La clé
   * @param {string} value  La valeur
   */
  set(key, value) {
    const data = this._readCookie();
    data[key] = value;
    this._setCookie(data);
  }

  /**
   * Retourne l'objet stocké dans le cookie au format json
   * @private
   * @return {*} L'objet stocké dans le cookie au format json
   */
  _readCookie() {
    const cookie_content = document.cookie.match("(^|;) ?" + this.name + "=([^;]*)(;|$)");
    if (!cookie_content) {
      return {};
    }

    try {
      let json = cookie_content[2];
      if (this.encode) {
        json = atob(decodeURIComponent(json));
      }
      return JSON.parse(json);
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  /**
   * Creation du cookie avec l'objet au format json
   * @private
   * @param {*} data L'objet à mettre dans le cookie au format json
   */
  _setCookie(data) {
    try {
      let json = JSON.stringify(data);
      if (this.encode) {
        json = encodeURIComponent(btoa(json));
      }
      document.cookie = this.name + "=" + json + ";path=/;domain=" + this.domain + ";";
    } catch (e) {
      console.error(e);
    }
  }
}

export { BaseCookie };
