/**
 * Composant pour le footer du portail
 */
import { footerData } from "../../services/settings-loader";
import { TagCommanderManager } from "../../services/tag-commander-manager";
import * as TagPlan from "../../tag-plan";

export default class NeufsneufFooter extends HTMLElement {
  /**
   * Initialise une nouvelle instance de type {@type NeufsneufFooter}.
   */
  constructor() {
    super();
    this.querySelector("lnc-web-footer").addEventListener("clickOnLink", this._onClickFooter.bind(this));
    this.setDataToFooter(footerData);
  }

  /**
   * Réaction au clic sur le composant lnc footer
   * @param event Event l'évènement de clic
   * @private
   */
  _onClickFooter(event) {
    const message = event.detail;
    if (message.id === "GERER_COOKIES_ID") {
      TagCommanderManager.showPrivacyCenter();
    } else {
      TagCommanderManager.tagClick(TagPlan[message.tag]);
    }
  }

  /**
   * Construction du footer
   * @param {FooterConfig[]} config La configuration du footer
   */
  setDataToFooter(config) {
    this.querySelector("lnc-web-footer")?.setAttribute("data", JSON.stringify(config));
  }
}
