import { LncBaseApiClientService } from "@odw/lnc-services/js-services/api-client/lnc-base-api-client.service";

import { PageManager } from "../../services/page-manager";
import { neufsneufSettings } from "../../services/settings-loader";
import { TagCommanderManager } from "../../services/tag-commander-manager";

/**
 * La configuration par défaut pour l'iframe
 */

const PYM_CONFIG = {
  optionalparams: false,
};

/**
 * Composant pour intégrer une page dans le portail.
 * La page est affichée dans une iframe géré par Pym.js : http://blog.apps.npr.org/pym.js/api/pym.js/1.3.2/
 * Lorsque l'attribut display passe à true, on vérifie l'état de vie du partenaire et on affiche la page.
 * Le composant vient fixer une hauteur à l'iframe en fonction de l'espace disponible à l'écran.
 * La hauteur fixée ne sera par inférieure à la hauteur min.
 * @class
 * @constructor
 * @public
 */
export default class IframeBase extends HTMLDivElement {
  /**
   * Le service pour faire les appels ajax.
   * @type {LncBaseApiClientService}
   */
  apiClientService = new LncBaseApiClientService("");
  /**
   * Une valeur indiquant si la microapp est up
   * @type {boolean}
   */
  isMicroappAlive = false;

  /**
   * Initialise une nouvelle instance de type {@type IframeBase}.
   */
  constructor() {
    super();

    if (this.microappConfig.url.startsWith("http")) {
      // Si l'URL commence par http, on prend l'URL entière
      this.url = this.microappConfig.url;
    } else {
      // Si elle est relative, on préfixe avec base_url
      this.url = this.getBaseUrl() + this.microappConfig.url;
    }

    if (this.microappConfig.urlVie.startsWith("http")) {
      // Si l'URL de vie commence par http, on prend l'URL entière
      this.urlVie = this.microappConfig.urlVie;
    } else {
      // Si elle est relative, on préfixe avec base_url
      this.urlVie = this.getBaseUrl() + this.microappConfig.urlVie;
    }

    // TODO Ariane à déplacer dans displayOnReady et ne pas modifier this.url
    if (this.microappConfig.withParams) {
      this.url += location.search;
    }

    this.config = this.microappConfig.config ? { ...PYM_CONFIG, ...this.microappConfig.config } : { ...PYM_CONFIG };
  }

  /**
   * Configurer le composant pour réagir aux changements de l'attribut display.
   * @return {string[]}
   * @override
   */
  static get observedAttributes() {
    return ["display"];
  }

  /**
   * Retourne la config de la microapp
   * @returns {MicroappConfig|DOMStringMap}
   * @private
   */
  get microappConfig() {
    return PageManager.current.microapps[this.id] || this.dataset;
  }

  /**
   * Callback appelé à chaque changement d'attribut.
   * Notamment utilisé pour créer le pymParent quand l'attribut display passe à true.
   * @param {string} name Nom de l'attribut.
   * @param {*} oldValue Ancienne valeur.
   * @param {*} newValue Nouvelle valeur.
   * @override
   */
  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "display" && newValue === "true") {
      this.checkAndDisplayMicroapp();
    }
  }

  /**
   * Action pour vérifier l'état de vie de la microapp et l'afficher.
   * Si la microapp n'est pas disponible, on affiche le bloc de maintenance.
   * Si la microapp est disponible, on l'affiche
   * @protected
   */
  checkAndDisplayMicroapp() {
    this.isUrlVieAlive()
      .then(() => {
        this.displayOnReady();
      })
      .catch(() => {
        this.showMaintenance();
      });
  }

  /**
   * Action pour vérifier l'état de vie de la microapp
   * Si l'état de vie de la microapp a déjà été vérifié, ok pas de revérification
   * @return {Promise<boolean>} La promesse d'une réponse
   * @protected
   */
  isUrlVieAlive() {
    return new Promise((resolve, reject) => {
      if (this.isMicroappAlive) {
        resolve(true);
        return;
      }
      if (this.urlVie.includes("madam") || this.urlVie.includes("mes-capa-reseau")) {
        this.apiClientService
          .get(this.urlVie)
          .then(() => {
            this.isMicroappAlive = true;
            resolve(true);
          })
          .catch(() => {
            this.isMicroappAlive = false;
            reject("Url de vie KO");
          });
      } else {
        this.apiClientService
          .getVersion(this.urlVie)
          .then(() => {
            this.isMicroappAlive = true;
            resolve(true);
          })
          .catch(() => {
            this.isMicroappAlive = false;
            reject("Url de vie KO");
          });
      }
    });
  }

  /**
   * Fonction qui renvoie la base URL utilisée :
   * est surchargée par certaines classes filles pour utiliser une autre base URL
   * Par défaut, pointe vers les VMs
   * @protected
   * @returns {string}
   */
  getBaseUrl() {
    return neufsneufSettings.envs.base_url_vm;
  }

  /**
   * Construction de l'iframe pour afficher la page
   * @protected
   */
  displayOnReady() {
    // Supprime la précédente iframe
    this.innerHTML = "";
    // Ajoute l'iframe
    this.insertAdjacentHTML(
      "beforeend",
      `<iframe src="${this.url}" width="100%" height= "100%" scrolling="auto" marginHeight="0" frameBorder="0"></iframe>`
    );
    this.style.height = this.calcIframeHeight() + "px";
  }

  /**
   * Affiche la page de maintenance
   * @protected
   */
  showMaintenance() {
    this.classList.add("d-none");
    TagCommanderManager.tagPage({
      page: "service_indisponible",
    });
    const maintenance_div = this.parentElement.querySelector(".maintenance-microapp");
    if (maintenance_div) {
      maintenance_div.classList.remove("d-none");
    }
  }

  /**
   * Calcule la hauteur pour l'iframe en fonction de l'espace disponible à l'écran et d'une hauteur min
   * @returns {number} la hauteur de l'iframe en px, attention à ajouter '+ "px"' pour setter la hauteur depuis un fichier js
   * @protected
   */
  calcIframeHeight() {
    const minHeight = this.microappConfig.iframeMinHeight || neufsneufSettings.iframes.default_min_height;
    const header = document.querySelector("header");
    const footer = document.querySelector("footer");
    const main = document.querySelector("main");
    const mainMarginBottom = parseInt(window.getComputedStyle(main, null).getPropertyValue("margin-bottom"));

    if (header && footer && main) {
      let maxPossibleHeight = window.innerHeight - header.offsetHeight - footer.offsetHeight - mainMarginBottom;
      return Math.max(minHeight, maxPossibleHeight);
    }

    return minHeight;
  }
}
